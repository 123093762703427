import React, {FC} from 'react';
import ReactCompareImage from 'react-compare-image';
// import './styles.scss';

const CompareImages: FC<{img1: any; img2: any, alt1: string, alt2: string}> = ({img1, img2, alt1, alt2}) => {
  return (
    <div style={{maxWidth: '500px', margin: 'auto', width: '100%'}}>
      <ReactCompareImage
        sliderLineColor="#7C3AED"
        leftImage={img1}
        rightImage={img2}
        aspectRatio="taller"
        rightImageCss={{width: '100%', height: 'auto'}}
        leftImageCss={{width: '100%', height: 'auto'}}
        leftImageAlt={alt1}
        rightImageAlt={alt2}
      />
    </div>
  );
};

export default CompareImages;
