import React, {useContext, useEffect, useState} from 'react';
import Layout from '../components/layout';
import SearchScraperWrapper from '../components/search-scraper-wrapper';
import Section from '../components/section';
import {getPackages, getUser} from '../requests';
import {UserContext} from '../context/user-context';
import ProductCard from '../components/pricing/productCard';
import searchImg1 from '../img/img-compare/search-img-1.png';
import searchImg2 from '../img/img-compare/search-img-2.png';
import imageSearchImg1 from '../img/img-compare/image-search-img-1.png';
import imageSearchImg2 from '../img/img-compare/image-search-img-2.png';
import mapsImg1 from '../img/img-compare/maps-img-1.png';
import mapsImg2 from '../img/img-compare/maps-img-2.png';
import newsImg1 from '../img/img-compare/news-img-1.png';
import newsImg2 from '../img/img-compare/news-img-2.png';

import googleMapsIcon from '../img/google-maps-icon.svg';
import googleImageSearchIcon from '../img/google-image-icon.svg';
import googleSearchIcon from '../img/google-search-icon.svg';
import googleNewsIcon from '../img/google-news-icon.svg';

import pricingIcon from '../img/payments/pricing-hint.png';

import icon1 from '../img/icon-1.svg';
import icon2 from '../img/icon-2.svg';
import CompareImages from '../components/compare-images';
import {closeIcon} from '../img/svg-icons';
import Switch from '../components/switch';

const IndexPage = () => {
  const [plans, setPlans] = useState({
    ltd: null as any,
    monthly: null as any,
    annual: null as any,
  });
  const [annual, setAnnual] = useState(true);
  const {user, setUser} = useContext(UserContext);
  const [showUpgradeContact, setShowUpgradeContact] = useState(true);
  const [allDisabled, setAllDisabled] = useState(false);

  const setPlansData = async () => {
    const plansLocal = await getPackages();
    const userPlan = user?.package_data?.name;
    userPlan === 'PRO_ANNUAL' ? setAllDisabled(true) : setAllDisabled(false);
    const monthlyPlans = plansLocal
      .filter((item: any) => item.name !== 'free' && item.name?.includes('MONTH') && item.product_type === 'stripe')
      .map((item: any) => {
        return {
          ...item,
          paypal_id: plansLocal.find((sub: any) => sub.product_type === 'paypal' && sub.name === item.name)?.id,
          disabled: (userPlan?.includes('STARTER') && item.name.includes('STARTER')) || userPlan?.includes('PRO'),
          limit: item.name.includes('STARTER') ? '25,000' : '100,000',
        };
      })
      .sort((a: any, b: any) => {
        if (a.price < b.price) {
          return -1;
        } else {
          return 1;
        }
      });
    const annualPlans = plansLocal
      .filter(
        (item: any) =>
          item.name !== 'free' &&
          item.name?.includes('ANNUAL') &&
          item.product_type === 'stripe' &&
          !item.name?.includes('OLD'),
      )
      .map((item: any) => {
        return {
          ...item,
          paypal_id: plansLocal.find((sub: any) => sub.product_type === 'paypal' && sub.name === item.name)?.id,
          realPrice: item.name === 'PRO_ANNUAL' ? 588 : 240,
          realPriceMonth: item.name === 'PRO_ANNUAL' ? 49 : 20,
          disabled: (userPlan === 'STARTER_ANNUAL' && item.name === 'STARTER_ANNUAL') || userPlan === 'PRO_ANNUAL',
          limit: item.name.includes('STARTER') ? '25,000' : '100,000',
        };
      })
      .sort((a: any, b: any) => {
        if (a.price < b.price) {
          return -1;
        } else {
          return 1;
        }
      });
    plansLocal &&
      setPlans({
        ltd: null as any,
        monthly: monthlyPlans,
        annual: annualPlans,
      });
  };

  useEffect(() => {
    setPlansData();
  }, [user]);

  const refetchUser = async () => {
    const userInfo = await getUser();
    userInfo && setUser(userInfo);
  };

  const handleCloseModal = () => setShowUpgradeContact(false);

  useEffect(() => {
    setTimeout(() => {
      const path = global?.window?.location?.href;
      if (path && path?.includes('#pricing')) {
        document?.body?.scrollHeight && global?.window?.scrollTo(0, document?.body?.scrollHeight);
      }
    }, 1000);
  }, []);

  return (
    <Layout
      bg
      title={'Google Search Scraper'}
      metaName={'description'}
      metaContent={
        'Get 1-Click access to Google search, Maps, News, or Images search results in a convenient spreadsheet format, just like magic.'
      }
    >
      <Section lightGrey className="pt-0">
        <div style={{maxWidth: '1000px', margin: 'auto', position: 'relative'}}>
          <img
            src={icon1}
            style={{position: 'absolute', top: '30px', left: '-10%', width: '60px'}}
            className="d-none d-xl-block"
            alt=""
          />
          <img
            src={icon2}
            style={{position: 'absolute', top: '200px', right: '-10%', width: '60px'}}
            className="d-none d-xl-block"
            alt=""
          />
          <h1
            className="text-center"
            style={{color: '#050038', fontSize: '36px', fontWeight: 600, lineHeight: 'normal'}}
          >
            Scraping Google has never been easier.
          </h1>
          <h2
            className="text-center mb-4 px-2"
            style={{color: '#516077', fontSize: '20px', fontWeight: 400, lineHeight: '30px'}}
          >
            Get 1-Click access to Google search, Maps, News, or Images search results in a convenient spreadsheet
            format, just like magic.
          </h2>
        </div>

        <SearchScraperWrapper />
      </Section>
      {!user?.id && (
        <>
          <Section light>
            <div className="col-12 col-md-6 m-auto">
              <div className="mb-2 d-flex">
                <img src={googleMapsIcon} alt="Google Maps" className="mx-auto mx-md-0" />
              </div>
              <h3 className="text-md-left mb-4" style={{color: '#050038'}}>
                Google Maps Scraper
              </h3>
              <div className="d-md-none my-3 d-flex">
                <CompareImages
                  img1={mapsImg1}
                  img2={mapsImg2}
                  alt1="Google Maps Scraper"
                  alt2="Google Maps Scraper Data"
                />
              </div>
              <p>
                Save valuable time by finding local B2B business leads in seconds with our 1-click access to Google Maps
                data.
              </p>
            </div>
            <div className="col-12 col-md-6 d-none d-md-flex">
              <CompareImages
                img1={mapsImg1}
                img2={mapsImg2}
                alt1="Google Maps Scraper"
                alt2="Google Maps Scraper Data"
              />
            </div>
          </Section>
          <Section lightGrey>
            <div className="col-12 col-md-6 d-none d-md-flex">
              <CompareImages
                img1={searchImg1}
                img2={searchImg2}
                alt1="Google Search Scraper"
                alt2="Google Search Scraper Data"
              />
            </div>
            <div className="col-12 col-md-6 m-auto">
              <div className="mb-2 d-flex">
                <img src={googleSearchIcon} alt="Google Search" className="mx-auto mx-md-0" />
              </div>
              <h3 className="text-md-left mb-4" style={{color: '#050038'}}>
                Google Search Scraper
              </h3>
              <div className="d-md-none my-3 d-flex">
                <CompareImages
                  img1={searchImg1}
                  img2={searchImg2}
                  alt1="Google Search Scraper"
                  alt2="Google Search Scraper Data"
                />
              </div>
              <p>
                Turn any Google search result into a spreadsheet, ideal for finding influencers, publishers, SEO link
                partners, lead prospecting, or researching at scale.
              </p>
              <p>
                Simply enter your keyword and location, and you'll get hundreds of search results in a spreadsheet file
                you can use in Excel, Numbers or Google Sheets.
              </p>
            </div>
          </Section>

          <Section light>
            <div className="col-12 col-md-6 m-auto">
              <div className="mb-2 d-flex">
                <img src={googleImageSearchIcon} alt="Google Images" className="mx-auto mx-md-0" />
              </div>
              <h3 className="text-md-left mb-4" style={{color: '#050038'}}>
                Google Images Scraper
              </h3>
              <div className="d-md-none my-3 d-flex">
                <CompareImages
                  img1={imageSearchImg1}
                  img2={imageSearchImg2}
                  alt1="Google Images Scraper"
                  alt2="Google Images Scraper Data"
                />
              </div>
              <p>
                Effortlessly retrieve images from Google by entering your keyword and easily save the results in a
                spreadsheet.
              </p>
            </div>
            <div className="col-12 col-md-6 d-none d-md-flex">
              <CompareImages
                img1={imageSearchImg1}
                img2={imageSearchImg2}
                alt1="Google Images Scraper"
                alt2="Google Images Scraper Data"
              />{' '}
            </div>
          </Section>
          <Section lightGrey>
            <div className="col-12 col-md-6 d-none d-md-flex">
              <CompareImages
                img1={newsImg1}
                img2={newsImg2}
                alt1="Google News Scraper"
                alt2="Google News Scraper Data"
              />
            </div>
            <div className="col-12 col-md-6 m-auto">
              <div className="mb-2 d-flex">
                <img src={googleNewsIcon} alt="Google News" className="mx-auto mx-md-0" />
              </div>
              <h3 className="text-md-left mb-4" style={{color: '#050038'}}>
                Google News Scraper
              </h3>
              <div className="d-md-none my-3 d-flex">
                <CompareImages
                  img1={newsImg1}
                  img2={newsImg2}
                  alt1="Google News Scraper"
                  alt2="Google News Scraper Data"
                />
              </div>
              <p>
                Get the latest news for any company or brand in seconds. This is ideal for stock market research,
                prospect research, or competitive research.
              </p>
            </div>
          </Section>
          <Section dark gradient>
            <div className="d-flex flex-wrap" style={{maxWidth: '800px', margin: 'auto'}}>
              <div className="col-12 col-md-6 p-3">
                <div className="card p-3 w-100 h-100">
                  <h3 style={{fontSize: '22px'}}>Get 1-Click Access To The World's Biggest Search Engine</h3>
                  <p>
                    With Search Scraper, you get 1-click access to the world's most extensive database of websites,
                    leads, news, and events in a convenient spreadsheet format. No programming or scraping experience is
                    needed.
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-6 p-3">
                <div className="card p-3 w-100 h-100">
                  <h3 style={{fontSize: '22px'}}>Get Search Results From Any Country</h3>
                  <p>
                    Enjoy the convenience of our 100% cloud-based Search Scraper. No software installation is required;
                    you can access search results from any country, not just your own. No need for VPNs or compromising
                    your IP address.
                  </p>
                </div>
              </div>
            </div>
          </Section>
          <Section lightGrey>
            <div>
              <h2 className="mb-2 text-center" style={{color: '#050038'}} id="pricing">
                Search Scraper Pricing Plans
              </h2>

              <p className="text-center section-text lg">
                Take advantage of our <span style={{color: '#7C3AED'}}> SPECIAL LAUNCH OFFER </span> and save{' '}
                <span style={{color: '#7C3AED'}}>50% OFF</span> our plans for a limited time only.
              </p>
              {/* <Counter date={getPromoDate()} title="DEAL ENDS IN..." /> */}
            </div>

            <div className="pricing-wrapper">
              <div className="d-inline-block position-relative wrap-el w-100">
                <Switch
                  text1="Monthly"
                  text2="Annually"
                  active={!annual}
                  onChange={(val: string) => setAnnual(val === 'Annually')}
                />
              </div>
              <div className="d-block d-lg-flex products-wrapper justify-content-center">
                {allDisabled && showUpgradeContact && (
                  <div className="text-center all-disabled">
                    <span onClick={handleCloseModal}>{closeIcon}</span>
                    <img src={pricingIcon} alt="icon" />
                    <div>Need to upgrade your plan? </div>
                    <div className="support">
                      Please contact{' '}
                      <a
                        href="mailto:support@searchscraper.io?subject=Search Scraper"
                        aria-label={'mail'}
                        target="_blank"
                        rel="noopener"
                      >
                        support@searchscraper.io
                      </a>
                      {'.'}
                    </div>
                  </div>
                )}

                {(annual ? plans.annual : plans.monthly)?.map((item: any, index: number) => (
                  <ProductCard
                    id={item.id}
                    name={item.name}
                    paypal_id={item.ltd ? item.ltd.paypal_id : item.paypal_id}
                    color={item.color}
                    stack={true}
                    count={1}
                    key={item.name}
                    price={item.price}
                    ltdPrice={item.ltd?.amount}
                    popular={false}
                    expariationDate={true}
                    // pendingPayment={pendingPayment}
                    user={!!(user && user.id)}
                    verified={true}
                    ltdUser={false}
                    validUser={true}
                    disabled={item.ltd ? item.ltd.disabled : item.disabled}
                    limit={item.limit}
                    refetchUser={refetchUser}
                    realPriceMonth={item.realPriceMonth}
                    realPriceAnnual={item.realPrice}
                    annual={annual}
                  />
                ))}
              </div>
              <div className="text-center mt-4" style={{color: '#516077', fontSize: '14px', fontWeight: 500}}>
                All purchases are covered by our 30 day no questions asked refund policy.
              </div>
            </div>
          </Section>
        </>
      )}
    </Layout>
  );
};

export default IndexPage;
